<template>
  <admin-user-component />
</template>

<script>
import AdminUserComponent from "@/components/admin/AdminUserComponent";

export default {
  name: "AdminUser",
  title: "Gestión de Usuarios | Turismo BC",
  components: {
    AdminUserComponent,
  },
};
</script>

<style scoped></style>
