/***********************************************/
// Capa de Servicios para el modulo de Usuarios.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + store.getters.getToken,
    },
  };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/users");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/users/${id}`);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  addRecord(formData, picture_id, imageFile) {
    // console.log('Adding Record.', formData);
    let result = null;
    let recordData = new FormData();
    recordData.append("id", formData.id);
    recordData.append("name", formData.name);
    recordData.append("last_name", formData.last_name);
    recordData.append("email", formData.email);
    recordData.append("password", formData.password);
    recordData.append("roles", formData.roles);
    recordData.append("active", formData.active);
    // console.log('Record-Data: ', recordData);
    axios
      .post(this.#baseUrl + "/users", recordData)
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
        let data = response.data;
        let user_id = data.id;
        this.uploadImage(user_id, picture_id, imageFile);
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  // Pushes posts to the server when called.
  updateRecord(formData, picture_id, imageFile) {
    let result = null;
    // console.log('Updating Record: %s %s ', formData.id, formData);
    let recordData = new FormData();
    recordData.append("id", formData.id);
    recordData.append("name", formData.name);
    recordData.append("last_name", formData.last_name);
    recordData.append("email", formData.email);
    recordData.append("password", formData.password);
    recordData.append("roles", formData.roles);
    recordData.append("active", formData.active);
    recordData.append("_method", "put");
    axios
      .post(`${this.#baseUrl}/users/${formData.id}`, recordData)
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
        let data = response.data;
        let user_id = data.id;
        this.uploadImage(user_id, picture_id, imageFile);
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  deleteRecord(id) {
    let result = null;
    // console.log('Deleting Record: %s ', id);
    axios
      .delete(`${this.#baseUrl}/users/${id}`)
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  uploadImage(user_id, picture_id, imageFile) {
    if (imageFile) {
      let formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("picture_id", picture_id);
      formData.append("file", imageFile);
      axios
        .post(this.#baseUrl + "/upload-image/users", formData)
        .then((response) => {
          return response.data;
          // console.log('Respuesta: ', response.data);
        })
        /* handle error */
        .catch((error) => {
          return this.handleError(error);
        });
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    // console.log(error.config);
    return valueError;
  }
})();
